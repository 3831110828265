import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BlockIntroduction from "../components/blockIntroduction"
import TitleTextCol from "../components/titleTextCol"
import Cta from "../components/cta"
import MetaImage from "../images/featured/loyall-featured-image-design-light.jpg"
import WildeFavicon from "../images/favicon-wilde.png"
import Accordions from "../components/accordions"
import ThreeImagesGallery from "../components/threeImagesGallery"
import Image from "../components/elements/image"
import { Container, Row, Col } from "react-bootstrap"
import LabelIntroduction from "../components/labelIntroduction"
import HeaderImageArrowDown from "../components/headerImageArrowDown"

const DesignPage = props => {
  return (
    <Layout>
      <Seo
        title="Loyall – Met design ontdekken we wat jouw bedrijf bijzonder maakt"
        image={{ src: MetaImage, height: 628, width: 1200 }}
        description="Inspireren is essentieel voor het opbouwen van sterke relaties met je doelgroep. Met design zorgen we voor deze inspiratie. Wat jij in gedachtenhebt brengen wij tot leven."
        pathname={props.location.pathname}
        favicon={WildeFavicon}
      />
      <div className="page-wrapper">
        <LabelIntroduction
          label="design"
          title="We are digital waymakers."
          subtitle="We ontdekken wat jouw bedrijf bijzonder maakt en brengen het tot leven met een betekenisvolle visuele identiteit."
        />
        <Container className="image-header">
          <Image fadeIn={true} imgsrc="loyall-label-design-header.jpg"/>
        </Container>
        <TitleTextCol
          title="Een mooie website werkt niet zonder een connectie met je doelgroep."
          paragh1="We geloven dat inspireren essentieel is voor het opbouwen van sterke relaties met je doelgroep. Emoties, passie, ideeën, ervaringen en belevingen zijn hierin leidend. Die zorgen er uiteindelijk voor dat we ons verbonden voelen met een merk."
          paragh2="Met design bereiken we dit doel en brengen we je organisatie tot leven. Het is het startpunt van de digital lifecycle."
        />
        <BlockIntroduction
          title="Hoe het werkt"
          intro={
            <h3>
              Wat jij in gedachten hebt brengen wij tot leven.
            </h3>
          }
          padding={{ top: 80, bottom: 0, left: 15, right: 15 }}
        />
        <HeaderImageArrowDown
          imageBig={"loyall-label-design-hoe-het-werk-1.jpg"}
          imageSmall={"loyall-label-design-hoe-het-werk-2.jpg"}
          elementId="design-two-images"
          delay={100}
          animationDuration={400}
        />
        <Container>
          <Row>
            <Col lg={{ span: 8, offset: 4 }}>
              <Accordions
                content={[
                  {
                    "title": "Moving strategy", 
                    "text": "Als we het hebben over het verhaal achter een merk en de manier hoe we dit laten zien, gaat het om moving strategy. De kern van ieder succesvolle organisatie zit ‘m in waarde. Of het nu gaat om het bieden van waardevolle producten, waardevolle propositie of een digitale identiteit. Die waarde brengen we in beweging.",
                    "list": ["Product definition", "Fields of influence", "Customer journey", "User stories", "Value proposition", "Digital identity"]
                  },
                  {
                    "title": "Digital experiences", 
                    "text": "De ervaring van je online bezoekers is cruciaal voor het succes van je digital lifecycle. We willen bezoekers inspireren, vasthouden en de juiste kant op sturen. Door middel van prototyping, UI/UX en uniek design zorgen we ervoor dat de digital experience sterker dan ooit wordt.",
                    "list": [" UI / UX", "Web & App design", "Interaction design", "Prototyping", " Dashboarding"]
                  },
                  {
                    "title": "Captivating motion", 
                    "text": "Beweging zorgt voor interactie. Het zorgt ervoor dat je de aandacht vasthoudt en op gevoelens kunt inspelen. Dat maakt het één van de krachtigste vormen van inspireren. Met animaties, transities en effecten creëren we beweging op je website of applicatie. Beweging die intuïtief aanvoelt en je verhaal versterkt.",
                    "list": ["Web animations", " Transitions & Effects", "2D animations", "Explainers", "Lower-third", "Openers"]
                  },
                  {
                    "title": "Solid identity", 
                    "text": "Je identiteit is gevormd, maar hoe uit je dit? Op welke manieren komt je branding terug? Denk aan e-mails, social media grids, print, flyers of andere uitingen. Al deze vormen hebben unieke features waar je rekening mee moet houden. Denk aan de juiste afmetingen, frameworks en positionering. Samen zorgt dit voor een solide identiteit.",
                    "list": ["Visual identity", "Promotional products", "E-mail design", "Print", "Invitations", "Invitations"]
                  }
                ]}
                padding={{ top: 60, bottom: 20, left: 15, right: 15 }}
              />
            </Col>
          </Row>
        </Container>
        <Cta
          verticalStyling={true}
          title=""
          intro="Klinkt goed toch? Check hier wat we al hebben gedaan."
          link="/"
          linkText="Binnenkort meer"
          className="pb-lg-0"
        />
        <ThreeImagesGallery
          imageBig={"loyall-label-design-portfolio-1.jpg"}
          imageSmall1={"loyall-label-design-portfolio-2.jpg"}
          imageSmall2={"loyall-label-design-portfolio-3.jpg"}
          padding={{ top: 0, bottom: 40, left: 15, right: 15 }}
          delay={250}
          animationDuration={600}
        />
        <Cta
          title=""
          intro="Doelstellingen vertalen naar design, development en marketing."
          link="/discovery"
          linkText="Ontdek de mogelijkheden"
        />
      </div>
    </Layout>
  )
}
export default DesignPage
